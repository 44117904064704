import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

//Icons
import Instagram from "../../icons/instagram.svg";
import Twitter from "../../icons/twitter.svg";
import Medium from "../../icons/medium.svg";
import LP from "../../icons/landingpage.png";
import Youtube from "../../icons/youtube.svg";
import Facebook from "../../icons/facebook.svg";

import ClearIcon from "@material-ui/icons/Clear";

import Moment from "react-moment";

import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import firebase, { firestore } from "../../Firebase/firebase";

const useStyles = makeStyles((theme) => ({
  taskHeader: {
    backgroundColor: "#1E1E2F",
    padding: theme.spacing(2),
    color: "white",
    borderRadius: "2px",
    opacity: "0.9",

    margin: theme.spacing(1),
  },
  taskCard: {
    backgroundColor: "red",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    margin: "0.5px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const TaskCard = ({
  task,
  url,
  creatorUserID,
  microTask,
  taskID,
  userID,
  creatorEmail,
  timestamp,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [taskActions, setTaskActions] = useState({
    open: false,
    completed: false,
  });

  const [cardState, setCardState] = useState({
    icon: "",
  });

  useEffect(() => {
    const iconArray = [
      { name: "twitter", img: Twitter },
      { name: "instagram", img: Instagram },
      { name: "medium", img: Medium },
      { name: "facebook", img: Facebook },
      { name: "youtube", img: Youtube },
    ];

    if (url) {
      iconArray.map((e) => {
        if (url.includes(e.name)) {
          setCardState({ icon: e.img });
          return;
        }
      });
    }
  }, []);

  const taskOptions = () => {
    const handleTask = () => {
      setTaskActions({ open: true });
      window.open(url, "_blank");
    };

    const handleApproved = async () => {
      const taskReference = firestore.doc(`/tasks/${taskID}`);
      await taskReference.set(
        {
          completedBy: firebase.firestore.FieldValue.arrayUnion(userID),
        },
        { merge: true }
      );

      var d = new Date();
      var newdate =
        "W:" +
        Math.ceil(d.getDate() / 7) +
        " M:" +
        +d.getMonth() +
        " Y:" +
        +d.getFullYear();
      const increment = firebase.firestore.FieldValue.increment(1);

      const userRefPublic = firestore.doc(`/users/${userID}/public/${userID}`);
      await userRefPublic.set(
        {
          userTasks: {
            tasksComplete: {
              taskID: firebase.firestore.FieldValue.arrayUnion(taskID),
            },
          },
          points: {
            pointsWeekly: { [newdate]: { points: increment } },
          },
        },
        { merge: true }
      );

      setTaskActions({ completed: true });
      console.log("task Approved");
    };

    const handleRejected = async () => {
      const taskReference = firestore.doc(`/tasks/${taskID}`);
      await taskReference.set(
        {
          completedBy: firebase.firestore.FieldValue.arrayUnion(userID),
        },
        { merge: true }
      );

      setTaskActions({ completed: true });

      console.log("task rejected");
    };

    if (!taskActions.open) {
      return (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          onClick={handleTask}
        >
          <Grid item xs={5}>
            <Button variant="contained" color="primary" fullWidth>
              <DoneAllOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: theme.spacing(1), // = 4 * 2
            }}
            onClick={handleApproved}
          >
            <DoneAllOutlinedIcon fontSize="small" />
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{
              margin: theme.spacing(1), // = 4 * 2
            }}
            onClick={handleRejected}
          >
            <ClearIcon fontSize="small" />
          </Button>
        </div>
      );
    }
  };

  let s1 = timestamp.seconds;
  var s2 = new Date().getTime() / 1000;
  var total = s2 - s1;
  var remainder = 172800 - total;

  let dateObj = new Date(remainder * 1000);
  let days = dateObj.getUTCDay();
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getSeconds();

  let timeString;

  console.log("remainder", remainder);
  console.log("dateObj", dateObj);
  console.log("days", days);
  console.log("hours", hours);
  console.log("minutes", minutes);

  var timeStringDays;
  if (days == 5) {
    days = 1;

    timeStringDays = days.toString() + " DAY\n";
    timeString = hours.toString() + " HR";
  } else if (hours >= 1) {
    timeString = hours.toString() + " HR";
  } else {
    timeString = minutes.toString() + " MIN";
  }

  return (
    <>
      {!taskActions.completed ? (
        <Grid item xs={12} sm={10} className={classes.taskCard}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={2} align="center">
              <img
                src={cardState.icon ? cardState.icon : LP}
                alt="Logo"
                height="50vh"
              />
            </Grid>
            <Grid item xs={3} align="center">
              <Button variant="outlined" size="small" disabled>
                <Typography variant="body2" noWrap color="primary">
                  Instructions
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={2} align="center">
              {microTask ? (
                <Typography variant="body2" noWrap>
                  {microTask.comment ? (
                    <span>
                      Comment
                      <br />
                    </span>
                  ) : null}
                  {microTask.likeUpvote ? (
                    <span>
                      Like/Upvote
                      <br />
                    </span>
                  ) : null}
                  {microTask.shareRetweet ? (
                    <span>
                      Share/Retweet
                      <br />
                    </span>
                  ) : null}
                  {microTask.special ? (
                    <span>
                      Special
                      <br />
                    </span>
                  ) : null}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={5} align="center">
              <Grid container justify="center" alignItems="center">
                <Grid item xs={7}>
                  {taskOptions()}
                </Grid>

                <Grid item xs={2}>
                  {timeStringDays ? (
                    <Typography variant="body2">{timeStringDays}</Typography>
                  ) : null}
                  <Typography variant="body2">{timeString}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default TaskCard;
