import React, { createContext, useState, useMemo } from "react";

export const UserContext = createContext("context");

//Provider job is to hold all the info then pass it down to all the components that fall as childrent to the provider
export const UserProvider = ({ children }) => {
  const [userContext, setUserContext] = useState(null);

  //wont re-render the component unless the value of setValue is different :0
  const memoizeValue = useMemo(() => ({ userContext, setUserContext }), [
    userContext,
    setUserContext,
  ]);

  //everything wrapped in the provider here has access to the value passed in
  return (
    <UserContext.Provider value={memoizeValue}>{children}</UserContext.Provider>
  );
};
