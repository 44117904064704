import React from "react";
import ReactDOM from "react-dom";
//Components
import App from "./App";
//Wrappers
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
//Styling
import "./index.css";
import Themes from "./Themes";
//Context
import { UserProvider } from "./Context/UserContext";
import { PopupProvider } from "./Context/PopupContext";
import { ModalProvider } from "./Context/ModalContext";
import { RoleProvider } from "./Context/RoleContext";

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
    <UserProvider>
      <RoleProvider>
        <PopupProvider>
          <ModalProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ModalProvider>
        </PopupProvider>
      </RoleProvider>
    </UserProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
