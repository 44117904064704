import React, { useState, useContext, useEffect, useRef } from "react";
//NPM Moduals
import Sky from "react-sky";
//Images
import LP from "../../icons/landingpage.png";
//Components
import SignIn from "../SignIn/SignIn";
//Context
import { UserContext } from "../../Context/UserContext";
//Material Ui
import {
  Grid,
  makeStyles,
  CssBaseline,
  Typography,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5),
    },
  },
  inv: {
    marginBottom: theme.spacing(0),
    textAlign: "center",
  },
}));

const LandingPage = ({ history }) => {
  const classes = useStyles();
  const { userContext, setUserContext } = useContext(UserContext);

  const rolePath = () => {
    if (userContext) {
      switch (userContext.role) {
        case "unverified":
          return (
            <Typography
              variant="h5"
              style={{ textAlign: "center", color: "red", marginTop: "2vh" }}
            >
              Your account is currently
              <br />
              under review.
            </Typography>
          );
          break;
        case "verified":
          history.push("/dashboard");
          break;
        case "admin":
          history.push("/dashboard");
          break;
      }
    } else {
      return <SignIn />;
    }
  };

  return (
    <div>
      <CssBaseline />

      <div style={{ opacity: "0.2" }}>
        <Sky
          images={{
            0: "https://image.flaticon.com/icons/svg/174/174855.svg",
            1: "https://image.flaticon.com/icons/svg/174/174848.svg",
            2: "https://image.flaticon.com/icons/svg/174/174883.svg",
            3: "https://image.flaticon.com/icons/svg/174/174879.svg",
            4: "https://image.flaticon.com/icons/svg/174/174857.svg",
            5: "https://image.flaticon.com/icons/svg/174/174870.svg",
            6: "https://image.flaticon.com/icons/svg/174/174869.svg",
            7: "https://image.flaticon.com/icons/svg/174/174872.svg",
            8: "https://image.flaticon.com/icons/svg/174/174875.svg",
            9: "https://image.flaticon.com/icons/svg/174/174845.svg",
            10: "https://image.flaticon.com/icons/svg/174/174837.svg",
            11: "https://image.flaticon.com/icons/svg/174/174858.svg",
            12: "https://image.flaticon.com/icons/svg/174/174844.svg",
            13: "https://image.flaticon.com/icons/svg/174/174865.svg",
            14: "https://image.flaticon.com/icons/svg/174/174874.svg",
          }}
          how={30}
          time={150}
          size={"30px"}
          background={"palettedvioletred"}
        />
      </div>

      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "90vh" }}
      >
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h2">
            e<span style={{ color: "red" }}>X</span>gcrew by Exchangily
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            INVITED <br />
            TO INFLUENCE.
          </Typography>
          {rolePath()}
          <div style={{ textAlign: "center" }}>
            <Link to="signup">
              <Button>
                <Typography variant="caption">No Account? Sign Up</Typography>
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={7} lg={6} container justify="center">
          <img src={LP} alt="Logo" style={{ width: "95%", height: "50%" }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
