import React, { useContext } from "react";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import BannerCard from "../Cards/BannerCard";
import StreetviewIcon from "@material-ui/icons/Streetview";
import EditProfileCard from "../Cards/EditProfileCard";
import AddTaskCard from "./Cards/AddTaskCard";
import { UserContext } from "../../Context/UserContext";

const useStyles = makeStyles((theme) => ({
  titleText: {
    textAlign: "center",
    paddingBottom: theme.spacing(3),
  },
  nameText: {
    textAlign: "center",
  },
}));

const AddTask = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { userContext, setUserContext } = useContext(UserContext);
  console.log("userConext", userContext);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          ADD TASK
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {userContext && userContext.userData && userContext.userData.email ? (
          <AddTaskCard
            userID={userContext.id}
            email={userContext.userData.email}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AddTask;
