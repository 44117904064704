// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBXDP4tdwGwWJzrlrZBUXeVLAgf1JVo64Q",
  authDomain: "excrew.firebaseapp.com",
  databaseURL: "https://excrew.firebaseio.com",
  projectId: "excrew",
  storageBucket: "excrew.appspot.com",
  messagingSenderId: "937666020994",
  appId: "1:937666020994:web:afbccbfecdc320655ad69b",
  measurementId: "G-20S9RZ95NS",
};

export default firebaseConfig;

//dev keys
// apiKey: "AIzaSyCtkNv322hsSDjodI29lGpjZScUs4XOL5I",
// authDomain: "excrew-dev.firebaseapp.com",
// databaseURL: "https://excrew-dev.firebaseio.com",
// projectId: "excrew-dev",
// storageBucket: "excrew-dev.appspot.com",
// messagingSenderId: "605725586593",
// appId: "1:605725586593:web:c345070c93c34cb7a82ce0",
// measurementId: "G-W9BQ8219VB"

//master keys
// apiKey: "AIzaSyBXDP4tdwGwWJzrlrZBUXeVLAgf1JVo64Q",
// authDomain: "excrew.firebaseapp.com",
// databaseURL: "https://excrew.firebaseio.com",
// projectId: "excrew",
// storageBucket: "excrew.appspot.com",
// messagingSenderId: "937666020994",
// appId: "1:937666020994:web:afbccbfecdc320655ad69b",
// measurementId: "G-20S9RZ95NS",
