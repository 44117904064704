import React, { createContext, useState, useMemo } from "react";

export const PopupContext = createContext("context");

//Provider job is to hold all the info then pass it down to all the components that fall as childrent to the provider
export const PopupProvider = ({ children }) => {
  const [popupContext, setPopupContext] = useState({
    open: null,
    message: null,
    vertical: "bottom",
    horizontal: "right",
  });

  //wont re-render the component unless the value of setValue is different :0
  const memoizeValue = useMemo(() => ({ popupContext, setPopupContext }), [
    popupContext,
    setPopupContext,
  ]);

  //everything wrapped in the provider here has access to the value passed in
  return (
    <PopupContext.Provider value={memoizeValue}>
      {children}
    </PopupContext.Provider>
  );
};
