import React, { useContext } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import BannerCard from "../Cards/BannerCard";
import StreetviewIcon from "@material-ui/icons/Streetview";
import EditProfileCard from "../Cards/EditProfileCard";
import { UserContext } from "../../Context/UserContext";

const useStyles = makeStyles((theme) => ({
  titleText: {
    textAlign: "center",
    paddingBottom: theme.spacing(3),
  },
  nameText: {
    textAlign: "center",
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { userContext, setUserContext } = useContext(UserContext);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          PROFILE BOARD
        </Typography>
      </Grid>

      {/*  <Grid item xs={false} sm={9} style={{ marginBottom: "1vh" }}>
        <Grid container alignItems="center">
          <StreetviewIcon fontSize="large" />

          <span style={{ marginLeft: "1vh" }}>
            <Typography variant="body2" className={classes.nameText}>
              John Doe
            </Typography>
            <Typography variant="caption">John Doe</Typography>
          </span>
        </Grid>
  </Grid>*/}

      <Grid item xs={12} sm={8}>
        {userContext && userContext.userData ? (
          <EditProfileCard
            userData={userContext.userData}
            social={userContext.social}
            id={userContext.id}
          />
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "60vh" }}
          >
            <CircularProgress size="4rem" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EditProfile;
