import React, { useState, useContext, useEffect, createRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Reaptcha from "reaptcha";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Link as RouterLink,
  withRouter,
  Redirect,
  Link,
} from "react-router-dom";
import { auth } from "../../Firebase/firebase";
import Alert from "@material-ui/lab/Alert";

import PersonPinIcon from "@material-ui/icons/PersonPin";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Verify from "../../Components/Verify/Verify";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
  errorMessage: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.secondary.main,
  },
  alertBoxSpacing: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  recap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SignIn = ({ history }) => {
  const d = new Date();
  const newdate =
    "W:" +
    Math.ceil(d.getDate() / 7) +
    " M:" +
    +d.getMonth() +
    " Y:" +
    +d.getFullYear();

  console.log("newdate", newdate);

  const classes = useStyles();

  const [signInState, setSignInState] = useState({
    email: "",
    password: "",
    valid: true,
    message: "",
  });

  const [recaptchaRef, setRecaptchaRef] = useState();

  // const handleBTN = async () => {
  //   // var token = await recaptchaRef.executeAsync();
  //   ////
  //   console.log("email", signInState.email);
  //   console.log("pass", signInState.password);

  //   try {
  //     await auth.signInWithEmailAndPassword(
  //       signInState.email,
  //       signInState.password
  //     );
  //     console.log("successful login");
  //     setSignInState({ ...signInState, valid: "good" });
  //   } catch (err) {
  //     setSignInState({
  //       ...signInState,
  //       valid: false,
  //       message: err.message,
  //     });
  //     console.log("error occured when signing in", err.message);
  //   }

  //   // apply to form data
  // };

  // useEffect(() => {
  //   console.log("signInState", signInState);

  // }, [signInState]);

  const handleBTN = async (e) => {
    e.preventDefault();
    recaptchaRef.execute();
  };

  const onChange = async (value) => {
    console.log("Captcha value:", value);
    console.log("email", signInState.email);
    console.log("pass", signInState.password);

    try {
      await auth.signInWithEmailAndPassword(
        signInState.email,
        signInState.password
      );
      console.log("successful login");
      setSignInState({ ...signInState, valid: "good" });
    } catch (err) {
      setSignInState({
        ...signInState,
        valid: false,
        message: err.message,
      });
      console.log("error occured when signing in", err.message);
      recaptchaRef.reset();
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        {signInState.valid ? null : (
          <Alert className={classes.alertBoxSpacing} severity="error">
            {signInState.message ===
            "The password is invalid or the user does not have a password"
              ? "Email or Password did not match, please try again"
              : signInState.message}
          </Alert>
        )}

        {signInState.valid === "good" ? (
          <Alert className={classes.alertBoxSpacing} severity="success">
            Login successful!
          </Alert>
        ) : null}

        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            onChange={(e) =>
              setSignInState({ ...signInState, email: e.target.value })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) =>
              setSignInState({ ...signInState, password: e.target.value })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          {/*<Verify
            captcha={captcha}
            setCaptcha={setCaptcha}
            signInState={signInState}
            setSignInState={setSignInState}
            email={signInState.email}
            pass={signInState.password}
          />*/}

          <form onSubmit={handleBTN}>
            <Button>
              <ReCAPTCHA
                sitekey="6Lc1rb8ZAAAAAPbFTpvCgiAQ_8gLLTqFZsHiR5u5"
                ref={(e) => setRecaptchaRef(e)}
                size="invisible"
                onChange={onChange}
              />
            </Button>

            <Button
              // onClick={handleBTN}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default withRouter(SignIn);
