import React, { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const LoadingState = ({ history }) => {
  const { userContext, setUserContext } = useContext(UserContext);

  useEffect(() => {
    switch (userContext?.role) {
      case "unverified":
        history.push("/");
        break;
      case "verified":
        history.push("/dashboard");
        break;
      case "admin":
        history.push("/dashboard");
        break;
      default:
        history.push("/");
        break;
    }
  }, [userContext?.role]);

  return (
    <div>
      <CircularProgress size="4rem"></CircularProgress>
    </div>
  );
};

export default withRouter(LoadingState);
