import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "./Context/UserContext";
import { CircularProgress } from "@material-ui/core";
import LoadingState from "./Components/LoadingState/LoadingState";

const PrivateRoute = ({ component: RouteComponent, ...extras }) => {
  const { userContext } = useContext(UserContext);

  console.log("context changed so here", userContext);

  return (
    <Route
      {...extras}
      render={(routeProps) =>
        userContext ? <RouteComponent {...routeProps} /> : <LoadingState />
      }
    ></Route>
  );
};
export default PrivateRoute;
