import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NoteIcon from "@material-ui/icons/Note";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { TextField, Grid, useTheme } from "@material-ui/core";
import StreetviewIcon from "@material-ui/icons/Streetview";
import { UserContext } from "../../Context/UserContext";
import firebase, { firestore } from "../../Firebase/firebase";

import InputAdornment from "@material-ui/core/InputAdornment";

//Icons
import TwitterIcon from "@material-ui/icons/Twitter";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import RoomIcon from "@material-ui/icons/Room";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import TelegramIcon from "@material-ui/icons/Telegram";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { PopupContext } from "../../Context/PopupContext";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1000px",
  },
  media: {
    height: 140,
  },
  actions: {
    display: "flex",
  },
  expand: {
    marginLeft: "auto",
  },

  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  nameText: {
    textAlign: "center",
  },
}));

export default function EditProfileCard({ userData, social, id }) {
  const classes = useStyles();
  const theme = useTheme();
  const { popupContext, setPopupContext } = useContext(PopupContext);

  const [profile, setProfile] = useState({
    fullName: userData.fullName,
    youtube: social.youtube,
    location: userData.location,
    telegram: social.telegram,
    twitter: social.twitter,
    facebook: social.facebook,
    instagram: social.instagram,
    exgAddress: userData.exgAddress,
  });

  const updateField = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("profile", profile);
    try {
      const userRefPublic = firestore.doc(`/users/${id}/public/${id}`);
      const time = firebase.firestore.FieldValue.serverTimestamp();

      userRefPublic.set(
        {
          userData: {
            fullName: profile.fullName,
            location: profile.location,
            exgAddress: profile.exgAddress,
          },
          social: {
            facebook: profile.facebook,
            instagram: profile.instagram,
            twitter: profile.twitter,
            facebook: profile.facebook,
            telegram: profile.telegram,
            youtube: profile.youtube,
          },
          userStats: {
            last_write: time,
          },
        },
        { merge: true }
      );

      console.log("edit profile successfully pushed");
      // setMessage(true);
      setPopupContext({
        open: true,
        message: "Success — profile updated",
        vertical: "top",
        horizontal: "right",
      });
    } catch (err) {
      console.log("error occured when updating edit profile ", err);
      setPopupContext({
        open: true,
        message: err.message,
        vertical: "top",
        horizontal: "right",
        severity: "error",
      });
    }
  };

  return (
    <Grid container justify="center">
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid item sm={9} style={{ marginBottom: "1vh" }}>
              <Grid container alignItems="center">
                <StreetviewIcon fontSize="large" />

                <span style={{ marginLeft: "1vh" }}>
                  <Typography variant="body2">{profile.fullName}</Typography>
                  <Typography variant="caption">{userData.email}</Typography>
                </span>
              </Grid>
            </Grid>
            <div className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="name"
                    autoFocus
                    variant="outlined"
                    required
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    value={profile.fullName}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <PersonPinIcon fontSize="large" color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="telegram"
                    label="Telegram"
                    name="telegram"
                    value={profile.telegram}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <TelegramIcon
                            fontSize="large"
                            style={{ color: "#0088cc" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Twitter"
                    name="twitter"
                    value={profile.twitter}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <TwitterIcon
                            fontSize="large"
                            style={{ color: "#00acee" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Instagram"
                    name="instagram"
                    value={profile.instagram}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <InstagramIcon fontSize="large" color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Facebook"
                    name="facebook"
                    value={profile.facebook}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <FacebookIcon
                            fontSize="large"
                            style={{ color: "#3b5998" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Youtube"
                    name="youtube"
                    value={profile.youtube}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: <InputAdornment>YT</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Location"
                    name="location"
                    value={profile.location}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <RoomIcon fontSize="large" color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="EXG Address"
                    name="exgAddress"
                    value={profile.exgAddress}
                    onChange={updateField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <AccountBalanceWalletIcon
                            fontSize="large"
                            style={{ color: "#008000" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>

          <CardActions className={classes.actions}>
            <Button
              size="large"
              color="primary"
              className={classes.expand}
              type="submit"
            >
              Save Profile
            </Button>
          </CardActions>
        </form>
      </Card>
    </Grid>
  );
}
