import React, { useContext, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Hidden, Typography, TextField, Button } from "@material-ui/core";
import { UserContext } from "../../Context/UserContext";
import { firestore } from "../../Firebase/firebase";
import BannerCard from "../../Components/Cards/BannerCard";
import AccountCircle from "@material-ui/icons/AccountCircle";
import DataTreeView from "./DataTreeView";
import JSONPretty from "react-json-pretty";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableView from "./TableView";
import { ModalContext } from "../../Context/ModalContext";
import ModalForm from "./ModalForm";
import { RoleContext } from "../../Context/RoleContext";

const useStyles = makeStyles((theme) => ({
  titleText: {
    textAlign: "center",
    paddingBottom: theme.spacing(3),
  },
  taskHeader: {
    backgroundColor: "#1E1E2F",
    padding: theme.spacing(1),
    color: "white",
    borderRadius: "2px",
    opacity: "0.9",

    margin: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },

  taskCard: {
    backgroundColor: "red",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    marginTop: theme.spacing(1.5),
    margin: theme.spacing(1),
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const UserLookup = () => {
  const classes = useStyles();

  //used for getting all of a users data
  const [find, setFind] = useState({
    email: null,
    curUser: null,
  });

  const updateField = (e) => {
    setFind({
      ...find,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const query = firestore.collectionGroup("public");

    query
      .where("userData.email", "==", find.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          console.log("found");

          setFind({
            ...find,
            curUser: [doc.data()],
          });
        });
      });
  };

  const { roleContext, setRoleContext } = useContext(RoleContext);

  //gets all the users ever
  useEffect(() => {
    setRoleContext([]);

    const query = firestore.collectionGroup("public").orderBy("userData.email");

    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        console.log(doc.id, " query => ", doc.data());
        const general = firestore.doc(`/users/${doc.id}/private/${doc.id}`);

        general.onSnapshot((snapShot) => {
          console.log("generalID", snapShot.data().role);
          setRoleContext((roleContext) => [
            ...roleContext,
            { role: snapShot.data().role, data: doc.data() },
          ]);
        });
      });
    });
  }, []);

  return (
    <div>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.titleText} noWrap>
            Active User Lookup Dashbaord
          </Typography>
        </Grid>

        <Grid
          item
          xs={9}
          container
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={3}>
            <Typography variant="h5" noWrap>
              Active Users
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <form onSubmit={handleSubmit}>
              <TextField
                id="input-with-icon-grid"
                label="Search For Email"
                fullWidth
                name="email"
                onChange={updateField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <TableView type={"verified"} />
        </Grid>

        <Grid item xs={9}>
          {/* this will be a modal */}
          <Grid item xs={12}>
            <Grid container spacing={1} justify="center" alignItems="flex-end">
              <JSONPretty id="json-pretty" data={find.curUser}></JSONPretty>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserLookup;
