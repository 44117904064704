import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import ModalForm from "./ModalForm";
import { ModalContext } from "../../Context/ModalContext";
import { RoleContext } from "../../Context/RoleContext";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function TableView({ type }) {
  const classes = useStyles();

  const { modalContext, setModalContext } = useContext(ModalContext);
  const { roleContext, setRoleContext } = useContext(RoleContext);

  var newArr = roleContext.filter((e) => {
    return e.role == type;
  });

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight: "60vh" }}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow style={{ backgroundColor: "#1e1e2f" }}>
              <TableCell style={{ color: "white" }}>Email</TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                Full Name
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                Role
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                Multiplier
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newArr.map((e, idx) => (
              <TableRow key={idx}>
                <TableCell component="th" scope="row">
                  {e.data.userData.email}
                </TableCell>
                <TableCell align="center">{e.data.userData.fullName}</TableCell>
                <TableCell align="center" onClick={() => alert("hhelo")}>
                  {e.role}
                </TableCell>
                <TableCell align="center">{e.data.points.multiplier}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() =>
                      setModalContext({
                        open: true,
                        id: e.data.userData.uid,
                        data: e.data,
                      })
                    }
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
