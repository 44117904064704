import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter, Link } from "react-router-dom";
import { Grid, CircularProgress, Badge, Button } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import TaskBoard from "../../Components/TaskBoardComponent/TaskBoard";
import EditProfile from "../../Components/EditProfileComponent/EditProfile";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddTask from "../../Components/AddTaskComponent/AddTask";
import "./Navbar.css";
import ProfileMenu from "../../ProfileMenu/ProfileMenu";
import TaskApproval from "../../Components/AdminComponents/AdminTaskApproval";
import AdminTaskApproval from "../../Components/AdminComponents/AdminTaskApproval";
import GroupIcon from "@material-ui/icons/Group";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { UserContext } from "../../Context/UserContext";
import UserLookup from "../UserLookup/UserLookup";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ActiveRequests from "../UserLookup/ActiveRequests";
import { firestore } from "../../Firebase/firebase";
import XLSX from "xlsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  bottomPush: {
    position: "absolute",
    bottom: 0,
    paddingBottom: 10,

    color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  titleColor: {
    color: "linear-gradient(90deg,#fbc879,#eb7f75,#a26aa7,#695aad,#3e51b0);",
  },
}));

const NavbarDrawer = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { userContext, setUserContext } = useContext(UserContext);

  //set the starting component
  const [currentComponent, setCurrentComponent] = useState("taskboard");

  //Functions
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenComponent = () => {
    if (currentComponent === "taskboard") {
      if (userContext) {
        return <TaskBoard userID={userContext.id} />;
      } else {
        return (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "60vh" }}
          >
            <CircularProgress size="4rem" />
          </Grid>
        );
      }
    } else if (currentComponent === "profile") {
      return <EditProfile />;
    } else if (currentComponent === "addtask") {
      return <AddTask />;
    } else if (currentComponent === "taskapproval") {
      return <AdminTaskApproval />;
    } else if (currentComponent === "userlookup") {
      return <UserLookup />;
    } else if (currentComponent === "activerequest") {
      return <ActiveRequests />;
    }
  };

  const itemsList = [
    {
      text: "Task Board",
      onClick: () => setCurrentComponent("taskboard"),
      icon: <AssignmentIcon />,
    },
  ];

  const itemsList2 = [
    {
      text: "Profile",
      onClick: () => setCurrentComponent("profile"),
      icon: <AccountBoxIcon />,
    },
    {
      text: "News - Coming Soon",
      onClick: () => setCurrentComponent("taskboard"),
      icon: <MailOutlineIcon />,
    },
  ];

  const itemsList3 = [
    {
      text: "Active User Dashboard",
      onClick: () => setCurrentComponent("userlookup"),
      icon: (
        <Badge badgeContent={"beta"} color="primary" variant="dot">
          <GroupIcon />{" "}
        </Badge>
      ),
    },
    {
      text: "Requests Dashboard",
      onClick: () => setCurrentComponent("activerequest"),
      icon: (
        <Badge badgeContent={"beta"} color="error" variant="dot">
          <PersonAddIcon />
        </Badge>
      ),
    },
    {
      text: "Task Approval",
      onClick: () => setCurrentComponent("taskapproval"),
      icon: (
        <Badge badgeContent={"beta"} color="error" variant="dot">
          <VerifiedUserIcon />
        </Badge>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            justify="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item align="center">
              <Link to="/">
                <Typography
                  variant="h4"
                  style={{ fontFamily: "Pragati Narrow" }}
                >
                  e<span style={{ color: "red" }}>X</span>gcrew{" "}
                  <span style={{ color: "red" }}>b</span>eta
                </Typography>
              </Link>
            </Grid>

            <Grid item>
              <ProfileMenu setCurrentComponent={setCurrentComponent} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {itemsList.map((e, index) => (
            <ListItem button key={e.text} onClick={e.onClick}>
              <ListItemIcon>{e.icon}</ListItemIcon>

              <ListItemText primary={e.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {itemsList2.map((e, index) => (
            <ListItem button key={e.text} onClick={e.onClick}>
              <ListItemIcon>{e.icon}</ListItemIcon>
              <ListItemText primary={e.text} />
            </ListItem>
          ))}
        </List>
        <Divider />

        {userContext?.role === "admin" ? (
          <List>
            {itemsList3.map((e, index) => (
              <ListItem button key={index} onClick={e.onClick}>
                <ListItemIcon>{e.icon}</ListItemIcon>
                <ListItemText primary={e.text} />
              </ListItem>
            ))}
          </List>
        ) : null}

        <div
          className={classes.bottomPush}
          onClick={() => setCurrentComponent("addtask")}
        >
          <ListItem button>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Add A Task"} />
          </ListItem>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/*<Button onClick={testClick}>Per Task</Button>
        <Button onClick={callWeekly}>Weekly</Button>
        <Button onClick={gatherData}>gatherData</Button>
        <Button onClick={download}>download</Button>

        {JSON.stringify(d)}
        <br />
        {JSON.stringify(myObj)}
        <br />
        {JSON.stringify(newdate)}
            <br />*/}

        {handleOpenComponent()}
      </main>
    </div>
  );
};

export default withRouter(NavbarDrawer);
