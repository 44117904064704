import React, { useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AlertBox from "../../Components/Alert/AlertBox";

import { auth, createProfileDoc } from "../../Firebase/firebase";
import InputAdornment from "@material-ui/core/InputAdornment";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import RedditIcon from "@material-ui/icons/Reddit";
import RoomIcon from "@material-ui/icons/Room";
import YouTubeIcon from "@material-ui/icons/YouTube";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Sky from "react-sky";
import "./SignUpStyles.css";
import Reaptcha from "reaptcha";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { PopupContext } from "../../Context/PopupContext";
import firebase from "../../Firebase/firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  tfTitle: {
    marginBottom: "1vh",
  },
  recap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}));

const SignUp = ({ history }) => {
  const classes = useStyles();
  const [snackState, setSnackState] = useState(false);
  const { popupContext, setPopupContext } = useContext(PopupContext);

  const [signUpState, setSignUpState] = useState({
    fullName: "hello",
    email: "",
    password: "",
    comfirmPassword: "",
    telegram: "",
    location: "",
    languages: "",
    youtube: "",
    facebook: "",
    twitter: "",
    instagram: "",
    experience: "",
    q1: "",
    q2: "",
    q3: "",
    exgAddress: "",
  });

  //factor off
  const {
    fullName,
    email,
    password,
    comfirmPassword,
    telegram,
    location,
    languages,
    facebook,
    youtube,
    twitter,
    instagram,
    experience,
    q1,
    q2,
    q3,
    exgAddress,
  } = signUpState;

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("signUpState", signUpState);

    if (signUpState.password !== signUpState.comfirmPassword) {
      setPopupContext({
        open: true,
        message: "Passwords do not match!",
        vertical: "top",
        horizontal: "right",
        severity: "error",
      });
      return;
    }

    try {
      //this will clear my form
      console.log("submitted");

      setPopupContext({
        open: true,
        message: "Checking Data...",
        vertical: "top",
        horizontal: "right",
        severity: "info",
      });

      const { user } = await auth.createUserWithEmailAndPassword(
        signUpState.email,
        signUpState.password
      );

      await createProfileDoc(user, {
        fullName,
        email,
        password,
        comfirmPassword,
        telegram,
        location,
        languages,
        youtube,
        facebook,
        twitter,
        instagram,
        experience,
        q1,
        q2,
        q3,
        exgAddress,
      });

      setPopupContext({
        open: true,
        message: "Success — eXgcrew submission successful",
        vertical: "top",
        horizontal: "right",
      });

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (error) {
      console.log("error", error);
      setPopupContext({
        open: true,
        message: error.message,
        vertical: "top",
        horizontal: "right",
        severity: "error",
      });
      console.log(error);
    }
  };

  const updateField = (e) => {
    setSignUpState({
      ...signUpState,
      [e.target.name]: e.target.value,
    });
  };

  const getExgAddress = () => {
    firebase.analytics().logEvent("sign_up");
    window.open("https://exchangily.com/");
  };

  return (
    <div>
      <Container component="main" maxWidth="xs" style={{ marginBottom: "8vh" }}>
        <CssBaseline />

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography variant="h4" style={{ fontFamily: "Pragati Narrow" }}>
            e<span style={{ color: "red" }}>X</span>gcrew
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ fontFamily: "Bebas Neue" }}>
                  Part 1 - Account Setup
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  autoFocus={true}
                  name="fullName"
                  variant="outlined"
                  required
                  fullWidth
                  label="Full Name"
                  onChange={updateField}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="comfirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="comfirmPassword"
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ fontFamily: "Bebas Neue" }}>
                  Part 2 - About you
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="telegram"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is you Telegram Username?"
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <TelegramIcon style={{ color: "#0088cc" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="facebook"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is your Facebook url? "
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <FacebookIcon style={{ color: "#3b5998" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="twitter"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is your Twitter url? "
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <TwitterIcon style={{ color: "#00acee" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="instagram"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is your Instagram url? "
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <InstagramIcon style={{ color: "brown" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="youtube"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is your Youtube url? "
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <YouTubeIcon style={{ color: "#fb3958" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="reddit"
                  variant="outlined"
                  required
                  fullWidth
                  label="What is your Reddit url? "
                  onChange={updateField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <RedditIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="location"
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  rows={2}
                  label="Where are you located (City/State/Country)"
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="languages"
                  variant="outlined"
                  required
                  fullWidth
                  label="Which languages do you speak? "
                  onChange={updateField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="experience"
                  variant="outlined"
                  required
                  fullWidth
                  label="Do you have experience with cryptocurrency? "
                  onChange={updateField}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ fontFamily: "Bebas Neue" }}>
                  Part 3 - Cryptocurrency Knowledge
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.tfTitle} variant="body2">
                    Describe the Exchangily Project to someone who is not very
                    familiar with cryptocurrency
                  </Typography>
                </Grid>
                <TextField
                  multiline
                  rows={4}
                  name="q1"
                  variant="outlined"
                  required
                  fullWidth
                  label=""
                  onChange={updateField}
                  inputProps={{
                    maxLength: "200",
                  }}
                  helperText={`${q1 ? q1.length : "0"}/200`}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.tfTitle} variant="body2">
                    Which cryptocurrency exchnages do you see as competitors for
                    Exchangily?
                  </Typography>
                </Grid>
                <TextField
                  multiline
                  rows={4}
                  name="q2"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={updateField}
                  inputProps={{
                    maxLength: "200",
                  }}
                  helperText={`${q2 ? q2.length : "0"}/200`}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.tfTitle} variant="body2">
                    What suggestions do you have for promoting Exchangily Dex to
                    new potential users?
                  </Typography>
                </Grid>
                <TextField
                  multiline
                  rows={4}
                  name="q3"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={updateField}
                  inputProps={{
                    maxLength: "200",
                  }}
                  helperText={`${q3 ? q3.length : "0"}/200`}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ fontFamily: "Bebas Neue" }}>
                  Part 4 - Addresses
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "2vh" }}>
                <Typography variant="body2" align="right">
                  <Link onClick={getExgAddress}>Need An EXG Address?</Link>
                </Typography>

                <TextField
                  name="exgAddress"
                  variant="outlined"
                  required
                  fullWidth
                  label="Exchangily Address"
                  onChange={updateField}
                  inputProps={{
                    maxLength: "33",
                  }}
                  helperText={`${exgAddress ? exgAddress.length : "0"}/33`}
                />
              </Grid>

              <div className={classes.recap}>
                <Button>
                  <Reaptcha
                    sitekey="6Ld7j7sZAAAAAJTnVllAWy5LOCnzS9mSknsFWgHI"
                    theme="dark"
                  />
                </Button>
              </div>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign Up
            </Button>

            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
};
export default SignUp;
