import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ModalContext } from "../../Context/ModalContext";
import { firestore } from "../../Firebase/firebase";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TableContainer,
  Table,
  TableHead,
  FormHelperText,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import JSONPretty from "react-json-pretty";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { RoleContext } from "../../Context/RoleContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//hold state for the current reads, saves the reads
var publicRoute;
var privateRoute;

const ModalForm = ({ open, id, data }) => {
  const classes = useStyles();
  const { setModalContext } = useContext(ModalContext);
  const { roleContext, setRoleContext } = useContext(RoleContext);
  const [localUser, setLocalUser] = useState();

  useEffect(() => {
    //this will stop from a spam of reads
    if (open) {
      privateRoute = firestore.doc(`/users/${id}/private/${id}`);
      publicRoute = firestore.doc(`/users/${id}/public/${id}`);

      //Debug
      // console.log("data here", data);
      // console.log("id here", id);
      // console.log("open", open);

      publicRoute.onSnapshot((snapShot) => {
        privateRoute.onSnapshot((snapShot2) => {
          console.log("checker", snapShot);
          setLocalUser({ public: snapShot.data(), private: snapShot2.data() });
        });
      });
    }
  }, [open]);

  const handleRoleChange = async (event) => {
    privateRoute.set({ role: event.target.value }, { merge: true });

    var newArr = roleContext.filter((e) => {
      return e.data.userData.uid != id;
    });

    setRoleContext(newArr);
  };

  const handleMultiplierChange = async (event) => {
    publicRoute.set(
      {
        points: {
          multiplier: event.target.value,
        },
      },
      { merge: true }
    );

    console.log(event.target.value);

    var newArr = roleContext.filter(
      (value, index) => roleContext.indexOf(value) === index
    );

    setRoleContext(newArr);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setModalContext({ open: false })}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {localUser && localUser.public && localUser.public.userData
            ? localUser.public.userData.email
            : null}
        </DialogTitle>
        <DialogContent style={{ maxHeight: "50vh" }}>
          <DialogContentText>
            Editing anything here will result in immediate changes to the
            database
          </DialogContentText>

          {localUser?.private?.role ? (
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">User role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleRoleChange}
                autoWidth={true}
                defaultValue={localUser.private.role}
              >
                <MenuItem value={"unverified"}>Unverified</MenuItem>
                <MenuItem value={"verified"}>Verified</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          {localUser?.public?.points?.multiplier ? (
            <FormControl className={classes.formControl}>
              <InputLabel>Multiplier</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleMultiplierChange}
                autoWidth={true}
                defaultValue={localUser.public.points.multiplier}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          <DialogContentText>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Socials</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Facebook : {data ? data.social.facebook : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Instagram : {data ? data.social.instagram : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Twitter : {data ? data.social.twitter : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Telegram : {data ? data.social.telegram : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>

          <DialogContentText>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Questions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Experience : {data ? data.questions.experience : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Question 1 : {data ? data.questions.q1 : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Question 2 : {data ? data.questions.q2 : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Question 3 : {data ? data.questions.q3 : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>

          <DialogContentText>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Extras</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Location : {data ? data.userData.location : null}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Languages : {data ? data.userData.languages : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalForm;
