import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { PopupContext } from "../../Context/PopupContext";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Popup = () => {
  const { popupContext, setPopupContext } = useContext(PopupContext);
  const { open, message, vertical, horizontal, severity } = popupContext;

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message={message}
        onClose={() =>
          setPopupContext({
            open: null,
            message: null,
            vertical,
            horizontal,
          })
        }
        autoHideDuration={6000}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Popup;
