import React from "react";
import Reaptcha from "reaptcha";
import Button from "@material-ui/core/Button";
import { auth } from "../../Firebase/firebase";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  recap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Verify = ({
  captcha,
  setCaptcha,
  signInState,
  setSignInState,
  email,
  pass,
}) => {
  const classes = useStyles();

  console.log("captcha", captcha);
  console.log("setCaptcha", setCaptcha);
  console.log("signInState", signInState);
  console.log("setSignInState", setSignInState);
  console.log("email", email);
  console.log("pass", pass);

  const onVerify = async () => {
    console.log("email", email);
    console.log("pass", pass);

    try {
      await auth.signInWithEmailAndPassword(email, pass);
      console.log("successful login");
      setSignInState({ ...signInState, valid: "good" });
    } catch (err) {
      setSignInState({
        ...signInState,
        valid: false,
        message: err.message,
      });
      console.log("error occured when signing in", err.message);
      captcha.reset();
    }
  };

  return (
    <div className={classes.recap}>
      <Button>
        <Reaptcha
          ref={(e) => setCaptcha(e)}
          sitekey="6Lc1rb8ZAAAAAPbFTpvCgiAQ_8gLLTqFZsHiR5u5"
          onVerify={onVerify}
          theme="dark"
          size="invisible"
        />
      </Button>
    </div>
  );
};

export default Verify;
