import React, { createContext, useState, useMemo } from "react";

export const ModalContext = createContext("context");

//Provider job is to hold all the info then pass it down to all the components that fall as childrent to the provider
export const ModalProvider = ({ children }) => {
  const [modalContext, setModalContext] = useState({
    open: false,
    id: null,
    data: null,
  });

  //wont re-render the component unless the value of setValue is different :0
  const memoizeValue = useMemo(() => ({ modalContext, setModalContext }), [
    modalContext,
    setModalContext,
  ]);

  //everything wrapped in the provider here has access to the value passed in
  return (
    <ModalContext.Provider value={memoizeValue}>
      {children}
    </ModalContext.Provider>
  );
};
