import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NoteIcon from "@material-ui/icons/Note";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { TextField, Grid, withStyles, Checkbox } from "@material-ui/core";
import StreetviewIcon from "@material-ui/icons/Streetview";
import LanguageIcon from "@material-ui/icons/Language";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import TaskSelection from "../../Buttons/TaskSelection";
import { UserContext } from "../../../Context/UserContext";
import { green } from "@material-ui/core/colors";
import uuid from "react-uuid";
import firebase, { firestore } from "../../../Firebase/firebase";
import Alert from "@material-ui/lab/Alert";
import { PopupContext } from "../../../Context/PopupContext";
import Reaptcha from "reaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1000px",
  },
  media: {
    height: 140,
  },
  actions: {
    display: "flex",
  },
  expand: {
    marginLeft: "auto",
  },

  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  nameText: {
    textAlign: "center",
  },
  recap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function AddTaskCard({ userID, email }) {
  const classes = useStyles();
  const { popupContext, setPopupContext } = useContext(PopupContext);
  const [captcha, setCaptcha] = useState();

  const taskID = uuid();

  const [taskState, setTaskState] = useState({
    taskID,
    userID,
    email,
    url: "",
    microTask: {
      likeUpvote: false,
      comment: false,
      shareRetweet: false,
      special: false,
    },
    timestamp: null,
    adminApproved: null,
    completedBy: [userID],
    pointMultiplier: 1,
  });

  const [handlePress, setHandlePress] = useState({
    initial: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handlePress.initial === true) {
      captcha.renderExplicitly();
      setHandlePress({ ...handlePress, initial: false });
      return;
    }
  };

  const updateField = (e) => {
    setTaskState({
      ...taskState,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setTaskState({
      ...taskState,
      microTask: {
        ...taskState.microTask,
        [event.target.name]: event.target.checked,
      },
    });
  };

  // const onVerify = () => {};

  const onVerify = async (recaptchaResponse) => {
    if (
      taskState.microTask.likeUpvote ||
      taskState.microTask.comment ||
      taskState.microTask.shareRetweet ||
      taskState.microTask.special
    ) {
      console.log("taskState.microTask", taskState.microTask);
      console.log("taskState.url", taskState.url);
      try {
        const taskReference = firestore.doc(`/tasks/${taskID}`);
        const userRefPublic = firestore.doc(
          `/users/${userID}/public/${userID}`
        );
        const time = firebase.firestore.FieldValue.serverTimestamp();

        await userRefPublic.set(
          {
            userTasks: {
              tasksCreated: {
                taskID: firebase.firestore.FieldValue.arrayUnion(taskID),
              },
            },
            userStats: {
              last_write: time,
            },
          },
          { merge: true }
        );

        // console.log(
        //   "hello from tasksCreated",
        //   userRefPublic.userTasks.tasksCreated
        // );

        await taskReference.set({
          taskID: taskID,
          creatorUserID: userID,
          creatorEmail: email,
          url: taskState.url,
          microTask: taskState.microTask,
          timestamp: null,
          adminApproved: taskState.adminApproved,
          pointMultiplier: taskState.pointMultiplier,
          completedBy: taskState.completedBy,
        });

        console.log("task successfully pushed login");
        setPopupContext({
          open: true,
          message: "Success — Task submitted for approval!",
          vertical: "bottom",
          horizontal: "right",
        });

        setTaskState({
          ...taskState,
          url: "",
          microTask: {
            likeUpvote: false,
            comment: false,
            shareRetweet: false,
            special: false,
          },
        });

        setCaptcha(null);
        captcha.reset();

        //send task id to context
      } catch (err) {
        console.log("error occured when submitting task ", err);
        setPopupContext({
          open: true,
          message: "Error Submitting Task!",
          vertical: "bottom",
          horizontal: "right",
        });
      }
    } else {
      //they didnt add a task
      return;
    }
  };

  return (
    <Grid container justify="center">
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <CardContent aria-label="Delete">
            <Grid container alignItems="center">
              <LanguageIcon fontSize="large" />
              <span style={{ marginLeft: "1vh" }}>
                <Typography variant="body2">URL</Typography>
              </span>
            </Grid>

            <div className={classes.paper}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="https://..."
                    autoFocus
                    name="url"
                    value={taskState.url}
                    onChange={updateField}
                    required
                  />
                </Grid>
                <TaskSelection
                  handleCheckboxChange={handleCheckboxChange}
                  taskState={taskState}
                />
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    Click on all actions that apply
                  </Typography>
                </Grid>

                <div className={classes.recap}>
                  <Button>
                    <Reaptcha
                      ref={(e) => setCaptcha(e)}
                      sitekey="6Ld7j7sZAAAAAJTnVllAWy5LOCnzS9mSknsFWgHI"
                      onVerify={onVerify}
                      theme="dark"
                      explicit
                    />
                  </Button>
                </div>
              </Grid>
            </div>
          </CardContent>

          <CardActions className={classes.actions}>
            <Button
              size="large"
              color="primary"
              className={classes.expand}
              type="submit"
            >
              Add
            </Button>
            <Button size="large" color="primary" className={classes.expand}>
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </Grid>
  );
}
