import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Hidden, Typography } from "@material-ui/core";
import TaskCardMobile from "../Cards/TaskCardMobile";
import TaskCard from "../Cards/TaskCard";
import BannerCard from "../Cards/BannerCard";
import TaskApprovalCard from "./Cards/TaskApprovalCard";
import { firestore } from "../../Firebase/firebase";

const useStyles = makeStyles((theme) => ({
  titleText: {
    textAlign: "center",
    paddingBottom: theme.spacing(3),
  },
  taskHeader: {
    backgroundColor: "#1E1E2F",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "white",
    borderRadius: "2px",
    opacity: "0.9",

    margin: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },

  taskCard: {
    backgroundColor: "red",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    margin: "0.5px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  infoCard: {
    textAlign: "center",
    backgroundColor: "red",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    marginTop: theme.spacing(4),
    margin: theme.spacing(1),
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const AdminTaskApproval = () => {
  const classes = useStyles();
  const [task, setTask] = useState([]);

  //this can be used on the userdash board
  useEffect(() => {
    const ref = firestore.collection("tasks").orderBy("timestamp");

    ref
      .where("adminApproved", "==", null)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          //console.log(doc.id, " => ", doc.data());
          // setTask([...task, doc.id]);
          setTask((task) => [...task, doc.data()]);
        });
      });
  }, []);

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.titleText} noWrap>
            ADMIN TASK APPROVAL BOARD
          </Typography>
        </Grid>

        <Grid item xs={false} sm={10}>
          <BannerCard>
            🔷 Increase brand awareness of Exchangily
            <br />
            🔷 Increase followers/subscribers on Exchangily's social media
            platforms
            <br />
            🔷 Influence users to join the Exchangily telegram channel
            https://t.me/exchangily_chat <br />
          </BannerCard>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify="center">
            <Grid item xs={3} className={classes.infoCard}>
              Tasks Approved : 1000+
            </Grid>
            <Grid item xs={3} className={classes.infoCard}>
              Tasks Rejected : 10+
            </Grid>
            <Grid item xs={3} className={classes.infoCard}>
              EXG Price Index : $0.26
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={10} className={classes.taskHeader}>
          <Grid container justify="center">
            <Grid item xs={2} align="center">
              PLATFORM
            </Grid>
            <Grid item xs={2} align="center">
              USER/TASKID
            </Grid>
            <Grid item xs={2} align="center">
              ACTION
            </Grid>
            <Grid item xs={3} align="center">
              TASK LINK
            </Grid>
            <Grid item xs={3} align="center">
              APPROVAL
            </Grid>
          </Grid>
        </Grid>

        {task.length == 0 ? (
          <Grid
            item
            xs={12}
            sm={10}
            className={classes.taskCard}
            style={{ textAlign: "center" }}
          >
            <p>No Tasks In Queue</p>
          </Grid>
        ) : (
          task.map((e) => {
            return (
              <TaskApprovalCard
                key={e.taskID}
                url={e.url}
                task={e}
                taskID={e.taskID}
                creatorUserID={e.creatorUserID}
                creatorEmail={e.creatorEmail}
                microTask={e.microTask}
              />
            );
          })
        )}
      </Grid>
    </div>
  );
};

export default AdminTaskApproval;
