import React, { useContext, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Hidden, Typography, CircularProgress } from "@material-ui/core";
import TaskCardMobile from "../Cards/TaskCardMobile";
import TaskCard from "../Cards/TaskCard";
import BannerCard from "../Cards/BannerCard";
import { UserContext } from "../../Context/UserContext";
import firebase, { firestore } from "../../Firebase/firebase";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  titleText: {
    textAlign: "center",
    paddingBottom: theme.spacing(3),
  },
  taskHeader: {
    backgroundColor: "#1E1E2F",
    padding: theme.spacing(1),
    color: "white",
    borderRadius: "2px",
    opacity: "0.9",

    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  taskCard: {
    backgroundColor: "red",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    margin: "0.5px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const TaskBoard = ({ userID }) => {
  const classes = useStyles();
  const [task, setTask] = useState([]);
  const [run, setRun] = useState(false);
  const { userContext, setUserContext } = useContext(UserContext);

  useEffect(() => {
    setTask([]);

    console.log("taskLen", task.length);

    const ref = firestore.collection("tasks").orderBy("timestamp");
    var expiredDate = new Date(new Date().setDate(new Date().getDate() - 2));
    var today = new Date(new Date().setDate(new Date().getDate()));
    console.log("today", today);
    console.log("expiredDate", expiredDate);
    console.log("timestamp", firebase.firestore.FieldValue.serverTimestamp());
    ref
      .where("timestamp", ">", expiredDate)
      .where("adminApproved", "==", true)
      .get()
      .then(function (querySnapshot) {
        console.log("found");
        querySnapshot.forEach(function (doc) {
          //check to see if the user has already compeleted the task or not
          let completedByArray = doc.data().completedBy;
          // console.log(doc.id, " => ", doc.data());
          // console.log("d => ", d);
          // console.count();
          if (!completedByArray.includes(userID)) {
            setTask((task) => [...task, doc.data()]);
          }
        });
        setRun(true);
      });

    console.log("task", task);
  }, []);

  const useWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || "xs"
    );
  };

  const width = useWidth();
  // console.log("width", width);

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.titleText} noWrap>
            TASK BOARD
            <br />
          </Typography>
        </Grid>

        <Grid item xs={false} sm={10}>
          <BannerCard>
            🔷 Increase brand awareness of Exchangily
            <br />
            🔷 Increase followers/subscribers on Exchangily's social media
            platforms
            <br />
            🔷 Influence users to join the Exchangily telegram channel
            https://t.me/exchangily_chat <br />
            🔷 Influence users to create an existing wallet on the dex
            https://exchangily.com/wallet/dashboard <br />
            🔷 Influence users to sign up for Exchangily's newsletters
            https://exchangily.com/
            <br />
            🔷 Influence users to acquire $FAB and $EXCHANGILY coins on our
            available exchange
            <br />
            🔷 Individual social media platform guides can be found at the
            following link: https://docs.google.com/
            <br />
          </BannerCard>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={10} className={classes.taskHeader}>
            <Grid container justify="center">
              <Grid item xs={2} align="center">
                PLATFORM
              </Grid>
              <Grid item xs={3} align="center">
                HELP
              </Grid>
              <Grid item xs={2} align="center">
                ACTIONS
              </Grid>
              <Grid item xs={5} align="center">
                TIME LEFT
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        {run && task.length != 0 && userContext ? (
          task.map((e) => {
            if (width == "sm" || width == "xs") {
              console.log("mobile");
              return (
                <Grid item xs={12} sm={10} key={e.taskID}>
                  <TaskCardMobile
                    key={e.taskID}
                    url={e.url}
                    task={e}
                    userID={userContext.id}
                    taskID={e.taskID}
                    creatorUserID={e.creatorUserID}
                    creatorEmail={e.creatorEmail}
                    microTask={e.microTask}
                    timestamp={e.timestamp}
                  />
                </Grid>
              );
            } else {
              console.log("desktop");
              return (
                <TaskCard
                  key={e.taskID}
                  url={e.url}
                  task={e}
                  userID={userContext.id}
                  taskID={e.taskID}
                  creatorUserID={e.creatorUserID}
                  creatorEmail={e.creatorEmail}
                  microTask={e.microTask}
                  timestamp={e.timestamp}
                />
              );
            }
          })
        ) : (
          <Grid
            item
            xs={12}
            sm={10}
            className={classes.taskCard}
            style={{ textAlign: "center" }}
          >
            {task.length == 0 && run ? (
              <p>No Tasks In Queue</p>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TaskBoard;
