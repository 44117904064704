import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import firebaseConfig from "./config";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

export const createProfileDoc = async (user, extraUserParam) => {
  //check to see if any data exisit in the user data
  if (user == null) {
    console.log("createProfileDoc is null");
    return;
  }

  //make reference to the database and the spot we can to place the user data
  const userRefPublic = firestore.doc(`/users/${user.uid}/public/${user.uid}`);
  const userRefPrivate = firestore.doc(
    `/users/${user.uid}/private/${user.uid}`
  );

  await updateUserData(userRefPublic, userRefPrivate, user, extraUserParam);

  return {
    userRefPublic: userRefPublic,
    userRefPrivate: userRefPrivate,
  };
};

const updateUserData = async (
  userRefPublic,
  userRefPrivate,
  user,
  extraUserParam
) => {
  const snapShot = await userRefPublic.get();

  //if the user does not already exisit, we will create one here
  if (snapShot.exists === false) {
    try {
      let signUpIp = "192.168.1.0";
      let signUpDate = new Date();
      let response = await fetch("https://api.ipify.org?format=json");
      await response.json().then((e) => {
        signUpIp = e.ip;
      });

      const time = firebase.firestore.FieldValue.serverTimestamp();

      await userRefPublic.set({
        userData: {
          uid: user.uid,
          fullName: extraUserParam.fullName,
          email: extraUserParam.email,
          location: extraUserParam.location,
          languages: extraUserParam.languages,
          exgAddress: extraUserParam.exgAddress,
          notes: "null",
          signUpDate,
        },
        social: {
          facebook: extraUserParam.facebook,
          instagram: extraUserParam.instagram,
          twitter: extraUserParam.twitter,
          facebook: extraUserParam.facebook,
          telegram: extraUserParam.telegram,
          youtube: extraUserParam.youtube,
        },
        questions: {
          experience: extraUserParam.experience,
          q1: extraUserParam.q1,
          q2: extraUserParam.q2,
          q3: extraUserParam.q3,
        },
        points: { multiplier: 1, pointsWeekly: 0, pointsTotal: 0 },
        userTasks: { tasksComplete: {}, tasksCreated: {} },
        userStats: {
          last_write: time,
        },
      });
      await userRefPrivate.set({ role: "unverified", signUpIp });

      //more events here https://developers.google.com/gtagjs/reference/event
      analytics.logEvent("sign_up");
      console.log("logged sign up event");
    } catch (err) {
      console.log("error creating user", err.message);
    }
  } else {
    analytics.logEvent("login");
    console.log("logged login event");

    await userRefPrivate.set(
      { lastOnline: user.metadata.lastSignInTime },
      { merge: true }
    );
  }
};

//used to query documents
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export default firebase;
