import React, { createContext, useState, useMemo } from "react";

export const RoleContext = createContext("context");

//Provider job is to hold all the info then pass it down to all the components that fall as childrent to the provider
export const RoleProvider = ({ children }) => {
  const [roleContext, setRoleContext] = useState([]);

  //wont re-render the component unless the value of setValue is different :0
  const memoizeValue = useMemo(() => ({ roleContext, setRoleContext }), [
    roleContext,
    setRoleContext,
  ]);

  //everything wrapped in the provider here has access to the value passed in
  return (
    <RoleContext.Provider value={memoizeValue}>{children}</RoleContext.Provider>
  );
};
