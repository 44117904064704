import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { Grid } from "@material-ui/core";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function TaskSelection({ handleCheckboxChange, taskState }) {
  const [state, setState] = React.useState({
    likeUpvote: false,
    comment: false,
    shareRetweet: false,
    special: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <Grid container justify="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={taskState.microTask.likeUpvote}
            onChange={handleCheckboxChange}
            name="likeUpvote"
          />
        }
        label="LIKE/UPVOTE"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={taskState.microTask.comment}
            onChange={handleCheckboxChange}
            name="comment"
            color="primary"
          />
        }
        label="COMMENT"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={taskState.microTask.shareRetweet}
            onChange={handleCheckboxChange}
            name="shareRetweet"
          />
        }
        label="SHARE/RETWEET"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={taskState.microTask.special}
            onChange={handleCheckboxChange}
            name="special"
            color="primary"
          />
        }
        label="SPECIAL"
      />
    </Grid>
  );
}
