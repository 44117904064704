import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";

//Icons
import Instagram from "../../icons/instagram.svg";
import Twitter from "../../icons/twitter.svg";
import Medium from "../../icons/medium.svg";
import LP from "../../icons/landingpage.png";
import Youtube from "../../icons/youtube.svg";
import Facebook from "../../icons/facebook.svg";

import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import firebase, { firestore } from "../../Firebase/firebase";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: "2vh",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    marginBottom: 12,
    marginTop: 12,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function TaskCardMobile({
  task,
  url,
  creatorUserID,
  microTask,
  taskID,
  userID,
  creatorEmail,
  timestamp,
}) {
  const classes = useStyles();

  const [taskActions, setTaskActions] = useState({
    open: false,
    completed: false,
  });

  const [cardState, setCardState] = useState({
    icon: "",
  });

  useEffect(() => {
    console.log("microTask", microTask);
    if (microTask) {
      console.log("microTask", microTask.comment);
    }
    const iconArray = [
      { name: "twitter", img: Twitter },
      { name: "instagram", img: Instagram },
      { name: "medium", img: Medium },
      { name: "facebook", img: Facebook },
      { name: "youtube", img: Youtube },
    ];

    if (url) {
      iconArray.map((e) => {
        if (url.includes(e.name)) {
          setCardState({ icon: e.img });
          return;
        }
      });
    }
  }, []);

  const taskOptions = () => {
    const handleTask = () => {
      setTaskActions({ open: true });
      window.open(url, "_blank");
    };

    const handleApproved = async () => {
      const taskReference = firestore.doc(`/tasks/${taskID}`);
      await taskReference.set(
        {
          completedBy: firebase.firestore.FieldValue.arrayUnion(userID),
        },
        { merge: true }
      );

      var d = new Date();
      var newdate =
        "W:" +
        Math.ceil(d.getDate() / 7) +
        " M:" +
        +d.getMonth() +
        " Y:" +
        +d.getFullYear();
      const increment = firebase.firestore.FieldValue.increment(1);

      const userRefPublic = firestore.doc(`/users/${userID}/public/${userID}`);
      await userRefPublic.set(
        {
          userTasks: {
            tasksComplete: {
              taskID: firebase.firestore.FieldValue.arrayUnion(taskID),
            },
          },
          points: {
            pointsWeekly: { [newdate]: { points: increment } },
          },
        },
        { merge: true }
      );

      setTaskActions({ completed: true });
      console.log("task Approved");
    };

    const handleRejected = () => {
      setTaskActions({ completed: true });

      console.log("task rejected");
    };

    if (!taskActions.open) {
      return (
        <Grid
          container
          direction="row"
          justify="space-evenly"
          onClick={handleTask}
        >
          <Grid item>
            <Button variant="contained" color="primary" fullWidth>
              <DoneAllOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container direction="row" justify="space-evenly">
          <Grid item onClick={handleApproved}>
            <Button variant="contained" color="primary">
              <DoneAllOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid item onClick={handleRejected}>
            <Button variant="contained" color="secondary">
              <ClearIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  let s1 = timestamp.seconds;
  var s2 = new Date().getTime() / 1000;
  var total = s2 - s1;
  var remainder = 172800 - total;

  let dateObj = new Date(remainder * 1000);
  let days = dateObj.getUTCDay();
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getSeconds();

  let timeString;

  console.log("remainder", remainder);
  console.log("dateObj", dateObj);
  console.log("days", days);
  console.log("hours", hours);
  console.log("minutes", minutes);

  var timeStringDays;
  if (days == 5) {
    days = 1;

    timeStringDays = days.toString() + " DAY\n";
    timeString = hours.toString() + " HR";
  } else if (hours >= 1) {
    timeString = hours.toString() + " HR";
  } else {
    timeString = minutes.toString() + " MIN";
  }

  return (
    <>
      {!taskActions.completed ? (
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid container justify="space-between" alignItems="center">
              <Typography className={classes.title} color="primary">
                PLATFORM
              </Typography>
              <Typography className={classes.title} color="primary">
                <img
                  src={cardState.icon ? cardState.icon : LP}
                  alt="Logo"
                  height="30vh"
                />
              </Typography>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Typography className={classes.title} color="primary">
                HELP
              </Typography>

              <Button variant="outlined" size="small">
                <Typography variant="body2" noWrap color="primary">
                  Instructions
                </Typography>
              </Button>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Typography className={classes.title} color="primary">
                ACTIONS
              </Typography>
              <Typography className={classes.title} color="primary">
                Like / Upvote! <br />
                Comment
              </Typography>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Typography className={classes.title} color="primary">
                WHATS NEXT
              </Typography>
              <span>{taskOptions()}</span>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Typography className={classes.title} color="primary">
                TIME LEFT
              </Typography>
              <Typography className={classes.title} color="primary">
                {timeStringDays}
                {timeString}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}
