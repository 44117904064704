import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, ButtonGroup } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";

//Icons
import Instagram from "../../../icons/instagram.svg";
import Twitter from "../../../icons/twitter.svg";
import LP from "../../../icons/landingpage.png";
import Youtube from "../../../icons/youtube.svg";
import Facebook from "../../../icons/facebook.svg";
import Medium from "../../../icons/medium.svg";

import Alert from "@material-ui/lab/Alert";
import { PopupContext } from "../../../Context/PopupContext";
import firebase, { firestore } from "../../../Firebase/firebase";

const useStyles = makeStyles((theme) => ({
  taskHeader: {
    backgroundColor: "#1E1E2F",
    padding: theme.spacing(2),
    color: "white",
    borderRadius: "2px",
    opacity: "0.9",

    margin: theme.spacing(1),
  },
  taskCard: {
    backgroundColor: "red",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: "#1E1E2F",
    borderRadius: "2px",
    opacity: "0.9",
    margin: "0.5px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

const TaskApprovalCard = ({
  task,
  url,
  creatorEmail,
  microTask,
  taskID,
  creatorUserID,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { popupContext, setPopupContext } = useContext(PopupContext);

  const [taskActions, setTaskActions] = useState({
    open: 0,
    value: 0,
    completed: false,
    multiplier: 0,
  });

  const [cardState, setCardState] = useState({
    icon: "",
  });

  useEffect(() => {
    const iconArray = [
      { name: "twitter", img: Twitter },
      { name: "instagram", img: Instagram },
      { name: "medium", img: Medium },
      { name: "facebook", img: Facebook },
      { name: "youtube", img: Youtube },
    ];

    if (url) {
      iconArray.map((e) => {
        if (url.includes(e.name)) {
          setCardState({ icon: e.img });
          return;
        }
      });
    }
  }, []);

  const copyTaskID = () => {
    navigator.clipboard.writeText(taskID);
    setPopupContext({
      open: true,
      message: "Copied TaskID To Clipboard",
      vertical: "bottom",
      horizontal: "right",
    });
  };

  const copyUserID = () => {
    navigator.clipboard.writeText(creatorUserID);
    setPopupContext({
      open: true,
      message: "Copied UserID To Clipboard",
      vertical: "bottom",
      horizontal: "right",
    });
  };

  const taskOptions = () => {
    const handleTask = () => {
      setTaskActions({ ...taskActions, open: taskActions.open + 1 });
      window.open(url, "_blank");
    };

    const handleApproved = async () => {
      const time = firebase.firestore.FieldValue.serverTimestamp();

      const taskReference = firestore.doc(`/tasks/${taskID}`);
      taskReference.update({
        adminApproved: true,
        timestamp: time,
      });

      const userRefPublic = firestore.doc(
        `/users/${creatorUserID}/public/${creatorUserID}`
      );

      console.log("userRefPublic", userRefPublic);
      console.log("userRefPublic", userRefPublic.points);

      const multi = async (docRef) => {
        try {
          var doc = await docRef.get();
          if (doc.exists) {
            console.log("here", doc.data()); //see below for doc object
            return doc.data().points.multiplier;
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.log("Error getting document:", error);
        }
      };

      const increment = firebase.firestore.FieldValue.increment(
        (await multi(userRefPublic)) * taskActions.value
      );

      var d = new Date();
      var newdate =
        "W:" +
        Math.ceil(d.getDate() / 7) +
        " M:" +
        +d.getMonth() +
        " Y:" +
        +d.getFullYear();

      userRefPublic.set(
        {
          points: {
            pointsWeekly: { [newdate]: { points: increment } },
          },
        },
        { merge: true }
      );

      //update the users weekly score with this formula (weeklyScore + taskValue = weeklyScore)
      setTaskActions({ ...taskActions, completed: true });
    };

    const handleRejected = () => {
      const taskReference = firestore.doc(`/tasks/${taskID}`);
      taskReference.update({
        adminApproved: false,
      });
      setTaskActions({ ...taskActions, completed: true });

      console.log("task rejected");
    };

    if (taskActions.open === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          onClick={handleTask}
        >
          <Grid item xs={8}>
            <Button variant="contained" color="primary" fullWidth>
              <DoneAllOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      );
    } else if (taskActions.open === 1) {
      return (
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                onClick={() =>
                  setTaskActions({ ...taskActions, value: 1, open: 2 })
                }
              >
                V1
              </Button>
              <Button
                onClick={() =>
                  setTaskActions({ ...taskActions, value: 2, open: 2 })
                }
              >
                V2
              </Button>
              <Button
                onClick={() =>
                  setTaskActions({ ...taskActions, value: 3, open: 2 })
                }
              >
                V3
              </Button>
              <Button
                onClick={() =>
                  setTaskActions({ ...taskActions, value: 4, open: 2 })
                }
              >
                V4
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      );
    } else if (taskActions.open === 2) {
      return (
        <Grid container direction="row" justify="space-evenly">
          <Grid item xs={4} onClick={handleApproved}>
            <Button variant="contained" color="primary">
              <DoneAllOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={4} onClick={handleRejected}>
            <Button variant="contained" color="secondary">
              <ClearIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <React.Fragment>
      {!taskActions.completed ? (
        <Grid item xs={12} sm={10} className={classes.taskCard}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={2} align="center">
              <img
                src={cardState.icon ? cardState.icon : LP}
                alt="Logo"
                height="50vh"
                onClick={copyTaskID}
              />
            </Grid>
            <Grid item xs={2} align="center">
              <Typography
                variant="body2"
                noWrap
                color="primary"
                onClick={copyUserID}
              >
                {creatorEmail}
              </Typography>
            </Grid>
            <Grid item xs={2} align="center">
              {microTask ? (
                <Typography variant="body2" noWrap>
                  {microTask.comment ? (
                    <span>
                      Comment
                      <br />
                    </span>
                  ) : null}
                  {microTask.likeUpvote ? (
                    <span>
                      Like/Upvote
                      <br />
                    </span>
                  ) : null}
                  {microTask.shareRetweet ? (
                    <span>
                      Share/Retweet
                      <br />
                    </span>
                  ) : null}
                  {microTask.special ? (
                    <span>
                      Special
                      <br />
                    </span>
                  ) : null}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={3} align="center">
              <Typography variant="body2" noWrap>
                {url}
              </Typography>
            </Grid>

            <Grid item xs={3} align="center">
              {taskOptions()}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default TaskApprovalCard;
