import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NoteIcon from "@material-ui/icons/Note";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles({
  root: {},
  media: {
    height: 140,
  },
});

const BannerCard = ({ children }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea>
        {/*<CardMedia
          className={classes.media}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        />*/}
        <CardContent>
          <Typography gutterBottom variant="h5">
            Objectives
          </Typography>
          <Typography variant="caption" color="textSecondary" component="p">
            {children}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <AssignmentIcon /> Platform Guide
        </Button>
        {/*<Button size="small" color="primary">
          Close
      </Button>*/}
      </CardActions>
    </Card>
  );
};

export default BannerCard;
