import React, { useEffect, useContext } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
//Components
import SignUp from "./Pages/SignUp/SignUp.jsx";
import LandingPage from "./Pages/LandingPage/LandingPage.jsx";
import NavbarDrawer from "./Pages/Navbar/NavbarDrawer.jsx";
import ModalForm from "./Pages/UserLookup/ModalForm.jsx";
import Popup from "./Components/PopupComponent/Popup.jsx";
//Firebase
import { auth, createProfileDoc } from "./Firebase/firebase";
//Context
import { UserContext } from "./Context/UserContext.js";
import { ModalContext } from "./Context/ModalContext.js";
import { PopupContext } from "./Context/PopupContext.js";
//Routes
import PrivateRoute from "./PrivateRoute";

const App = ({ history }) => {
  const { setUserContext } = useContext(UserContext);
  const { popupContext } = useContext(PopupContext);
  const { modalContext } = useContext(ModalContext);
  //dev to master

  useEffect(() => {
    const unsubsribeFromAuth = auth.onAuthStateChanged(async (userObject) => {
      //Debug Statement: used to check if im signed in
      userObject
        ? console.log("signed in with", userObject.uid)
        : console.log("signed out");

      //checks if the user already exisits in my database
      if (userObject != null) {
        //They exisit so fill the userContext

        const { userRefPublic, userRefPrivate } = await createProfileDoc(
          userObject
        );

        await userRefPublic.onSnapshot((snapShot) => {
          userRefPrivate.onSnapshot((snapShot2) => {
            setUserContext({
              id: snapShot.id,
              ...snapShot.data(),
              ...snapShot2.data(),
            });
          });
        });
      } else {
        //pretty much just set the user back to null, as not signed in user is signed out
        //called when the user is signed out
        setUserContext(userObject);
        //Debug Statement
        console.log("userObject is null");
      }
    });

    return () => unsubsribeFromAuth();
  }, []);

  return (
    <div>
      {popupContext ? <Popup /> : null}
      {modalContext ? (
        <ModalForm
          open={modalContext.open}
          id={modalContext.id}
          data={modalContext.data}
        />
      ) : null}

      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/signup" component={SignUp} />

        <PrivateRoute exact path="/dashboard" component={NavbarDrawer} />

        {/*<Route exact path="/dashboard" component={NavbarDrawer} />*/}

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

export default App;
